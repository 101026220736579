<template>
  <v-alert class="error-custom" type="error" v-if="message" dismissible>
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  name: "ApiErrorMessage",
  props: ["message"],
};
</script>
